<template>
  <main>
    <h1 class="text-sm font-bold">Sweep Page</h1>
    <div class="border-default mt-5 rounded shadow p-10">
      <div
        class="flex flex-col justify-center items-center py-3 bg-blue-50 border-default border-blue-300"
      >
        <label class="text-sm" for="sweep">Balance</label>
        <h1 class="font-bold text-xl">N {{ $route.params.amount }}</h1>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    getALLAccounts() {
      this.$store.dispatch("FETCH_ALL_ACCOUNTS");
    },
  },
  async created() {
    this.getALLAccounts();
  },
};
</script>
